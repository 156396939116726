import {computed, onMounted, ref} from "vue";
import {env} from "~/utils.js";

const loading_title_text = '...';

const title_prefix = ref('');
const title_suffix = ref('');

export const full_title = computed(() => {
    const is_production = env === 'production';

    if (title_prefix.value) {
        return (!is_production ? '💥 ' : '') + title_prefix.value + (title_suffix.value ? ` | ${title_suffix.value}` : '')
    }
    if (title_suffix.value) {
        return (!is_production ? '💥 ' : '') + title_suffix.value;
    }
    return (!is_production ? '💥️ ' : '') + `DASH`;
});

export function setTitlePrefix(prefix: string) {
        title_prefix.value = prefix;
}

export function setTitleSuffix(suffix: string) {
        title_suffix.value = suffix;
}

export function resetTitle() {
    title_prefix.value = '';
    title_suffix.value = '';
}

export function setLoadingTitle() {
    resetTitle();
    setTitlePrefix(loading_title_text);
}

export function checkValidTitle() {
    if (title_prefix.value === loading_title_text) {
        resetTitle();
    }
}
